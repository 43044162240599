import React from "react";
import validator from "../../images/validator-new.svg";
import Network from "../../images/theNetwork.svg";

export default function HeroSection() {
  return (
    <>
      <div className="bg-white pt-[100px]  lg:pt-0 container mx-auto lg:p-[100px] md:p-[50px]  p-[30px]   lg:gap-[120px] gap-[30px] justify-center items-center flex flex-col lg:flex-row ">
        <div className="z-10 flex flex-col justify-center">
          <div className="">
            <h1 className="flex flex-col items-start md:items-center md:flex-row md:-mt-24">
              <span className="text-[50px] text-center md:mb-12 lg:mb-0 mx-auto lg:text-left lg:mx-0 font-medium md:text-[64px] bg-clip-text text-transparent  bg-gradient-to-r from-[#4789CD] via-[#28D4F5] to-[#59D99D] inline-block pt-1.5 md:pt-0">
                Validators
              </span>
            </h1>
            <div className="mx-auto mb-6 font-medium text-left">
              <p className="md:text-[22.77px] text-center lg:text-left text-[18px] py-[40px] md:py-0">
                Deploy your node on the Fetch.ai chain and help secure the
                network whilst earning FET.
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-center h-96">
          <img src={validator} className="w-full rounded-2xl" alt="mission 3" />
        </div>
      </div>
      <div className="bg-white  pb-[100px] justify-between items-center container mx-auto lg:px-[100px] px-[30px] w-full flex flex-col gap-[30px] lg:gap-[120px] lg:flex-row ">
        <div className="">
          <img
            src={Network}
            className="md:w-full rounded-2xl object-cover h-[450px] w-[450px] md:h-full"
            alt="mission 3"
          />
        </div>
        <div className="max-w-[450px]">
          <h4 className="md:text-[46px] text-[40px] leading-12 text-left tracking-normal mb-4 font-bold">
            The&nbsp;
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#4789CD] to-[#3CD7F6]">
              Network
            </span>
          </h4>
          <div className="text-left">
            <p className="text-[16px] ">
              The Fetch.ai network is an interchain protocol, based on the
              Cosmos-SDK, and uses a high-performance WASM-based smart contract
              language (Cosmwasm) to allow advanced cryptography and machine
              learning logic to be implemented on-chain.
              <br />
              <br />
              This also allows the Fetch network to serve as a layer-2 network
              for Ethereum and as an interchain bridge to the rest of the
              blockchain world.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
