import React from "react";
import "../../styles/style.css";
const textArray = [
  "The hardware resources for running a validator node largely depend on the network load. As a recommended configuration we suggest the following requirements.",
  "Uptime is incredibly important for being a validator. It is expected that validators will have appropriate redundancies for computing, power, connectivity etc.",
  "While the blockchain itself is highly replicated it is also expected that validators will perform local storage backups in order to minimize validator down time.",
];
const numberArray = [
  {
    title: "8 GB",
    description: "RAM",
    subDescription: "",
  },
  {
    title: "500 GB",
    description: "SSD",
    subDescription: "",
  },
  {
    title: "400 MB/S",
    description: "INTERNET",
    subDescription: "",
  },
  {
    title: "Linux OS",
    description: "(Ubuntu 22.04 or higher)",
    subDescription: "MacOS",
  },
  {
    title: "4x CPU",
    description: "Intel or AMD",
    subDescription: "with SSE4.1, SSE4.2 and AVX flags (use lscpu to verify)",
  },
];

export default function ValidatorSection3() {
  return (
    <>
      <div className="py-[50px] container lg:px-[120px] px-[30px] mx-auto md:pt-[100px]">
        <h2 className=" font-bold text-[46px]   leading-[48px] text-[#0D283E] text-center">
          Joining{" "}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
            as a Validator{" "}
          </span>
          with Fetch.ai
        </h2>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-x-[21px] md:py-[80px] pt-[40px]">
          {textArray.map((text, index) => (
            <div key={index} className="">
              <p className="text-left text-[16px] mt-[20px]">{text}</p>
            </div>
          ))}
        </div>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-x-[21px] gap-y-[8px]">
          {numberArray.map((text, index) => (
            <div key={index} className="md:mx-4 md:my-4">
              <p className="text-center font-bold lg:text-[56px] text-[50px] text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
                {text.title}
              </p>
              <p className="text-center font-semibold text-[16px]">
                {text.description}
              </p>
              {text.subDescription.length > 0 && (
                <p className="px-2 text-center md:px-8">
                  {text.subDescription}
                </p>
              )}
            </div>
          ))}
        </div>
      </div>
      <section className="bg-white ">
        <div className="py-[50px] px-[20px] container mx-auto bg-white md:py-[100px] md:px-[120px] ">
          <h2 className=" font-bold text-[46px]   leading-[48px] text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400 text-center">
            Technical <span className="text-[#0D283E]">Information</span>
          </h2>
          <div className="relative mt-8 md:mt-16 md:bg-pattern bg-technical-information">
            {/* Box 1 */}
            <div className="md:w-1/2">
              <div className="bg-gradient-to-br from-[#4789CD] via-[#478acd00] to-[#478acd7a] px-[1.5px]  rounded-3xl ">
                <div
                  style={{
                    boxShadow: "20px 20px 60px 0px rgba(71, 137, 205, 0.16)",
                  }}
                  className="bg-white rounded-3xl text-center back p-[40px]"
                >
                  <h4 className="mb-4 font-bold">Where is the Source Code?</h4>
                  <p className="text-gray-600">
                    The source code for the Fetch.ai ledger can be found on
                    Github, which also contains installation instructions. These
                    involve installing Golang and a C++ compiler that is used to
                    build several specialized cryptographic libraries.
                  </p>
                </div>
              </div>
            </div>

            {/* Box 2 */}
            <div className="md:w-1/2 md:ml-[45%] md:mt-[-5%]">
              <div className="bg-gradient-to-br  from-[#4789CD] to-[#3CD7F6] px-[1.5px]  rounded-3xl ">
                <div
                  style={{
                    boxShadow: "20px 20px 60px 0px rgba(71, 137, 205, 0.16)",
                  }}
                  className="text-center back p-[40px]"
                >
                  <h4 className="mb-4 font-bold text-white">
                    Becoming a Validator
                  </h4>
                  <p className="text-white">
                    Validator candidates can bond their own FET and have FET
                    delegated, or staked to them by other FET holders.
                    Validators can contribute to the governance of Fetch.ai.
                    Validators are relied upon to keep the Fetch.ai network
                    running. It is an important role and should be taken as
                    such. If validators double sign or have prolonged downtime,
                    their staked FET (including FET of users that delegated to
                    them) could be slashed.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-[50px] px-[20px] container mx-auto  md:py-[100px]">
        <h2 className=" font-bold text-[46px]   leading-[48px] text-[#0D283E] text-center">
          After Setting up {""}
          <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
            a Node
          </span>
        </h2>
        <div className="grid md:grid-cols-3 grid-cols-1 gap-x-[21px] gap-y-[40px] pt-[50px] md:pt-[80px] ">
          <div className="max-w-[366px] mx-auto text-center">
            <div className="flex items-center justify-center">
              <p className="bg-[#0D283E] text-white py-[10px] px-[20px] uppercase max-w-[118px]  rounded-[40px]">
                Step 01
              </p>
            </div>

            <p className="text-center text-[#0D283E] text-[18px] pt-[25px] font-semibold">
              Create a dedicated Validator website, Telegram and Twitter account
            </p>
            <p className="text-center text-[#0D283E] text-[16px] pt-[20px] font-light">
              This is important since delegators will want to have information
              about the entity to which they are delegating their FET.
            </p>
          </div>
          <div className="max-w-[302px] mx-auto text-center">
            <div className="flex items-center justify-center">
              <p className="bg-[#0D283E] text-white py-[10px] px-[20px] uppercase max-w-[118px]  rounded-[40px]">
                Step 02
              </p>
            </div>
            <p className="text-center text-[#0D283E] text-[18px] pt-[25px] font-semibold">
              Join Discord
            </p>
            <p className="text-center text-[#0D283E] text-[16px] pt-[20px] font-light">
              Join{" "}
              <span className="underline">
                <a
                  href="https://discord.com/invite/fetchai"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fetch.ai&#39;s Discord Server
                </a>
              </span>{" "}
              and introduce yourself for access to the Validators channel.
            </p>
          </div>
          <div className="max-w-[385px] mx-auto text-center">
            <div className="flex items-center justify-center">
              <p className="bg-[#0D283E] text-white py-[10px] px-[20px] uppercase max-w-[118px]  rounded-[40px]">
                Step 03
              </p>
            </div>
            <p className="text-center text-[#0D283E] text-[18px] pt-[25px] font-semibold">
              Add your Node
            </p>
            <p className="text-center text-[#0D283E] text-[16px] pt-[20px] font-light">
              <span>
                If you wish to be considered for the next round of Delegations
                please{" "}
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf0WFkjloscwsJrpBSbnMC-UAmP-raQA3U0HZZGSApi4xCYgQ/viewform?pli=1"
                  target="_blank"
                  className="underline"
                  rel="noreferrer"
                >
                  fill out this form.
                </a>
              </span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
