import React from "react";
import HeroSection from "../components/validators/HeroSection";
import RolesRevenue from "../components/validators/Roles&Revenue";
import Layout from "../components/layout";
import ValidatorSection3 from "../components/validators/ValidatorSection3";
import { useBannerWidthContext } from "../context/BannerWidthContext";
import { useBannerDimensions } from "../hooks/useBannerDimensions";

function Network() {
  const { dispatch } = useBannerWidthContext();
  const bannerRef = useBannerDimensions(dispatch);
  return (
    <Layout headerClass="relative">
      <div ref={bannerRef}>
        <HeroSection />
      </div>
      <RolesRevenue />
      <ValidatorSection3 />
    </Layout>
  );
}
export default Network;
