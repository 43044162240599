import React from "react";
import classNames from "classnames";
import { useRef } from "react";
import blueTick from "../../images/blueTick.svg";
import toolBox from "../../images/toolBox.svg";
import greenGlow from "../../images/green_glow.svg";
const textArray = [
  "Greater say in the future direction of the protocol through their role in governance.",
  "Reputation as a valued member of the community.",
  "Faster submission of transactions and direct access to the network operation.",
];
const listData = [
  {
    heading: "Block Rewards (FET)",
    description:
      "Rewards are provided for every block that is produced by a validator. The inflation rate is set at an annual rate of 3% during the first three years of the network's operation.",
  },
  {
    heading: "Transaction Fees",
    description:
      "All transactions that are submitted to the chain are charged a transaction fee in FET.",
  },
];
const RolesRevenue = ({ cardSectionClasses, classes, titleSectionClasses }) => {
  const socialCardsRef = useRef(null);

  return (
    <>
      <section
        className={classNames(
          " py-[110px] px-6 container mx-auto  md:py-[100px]",
          {
            [classes]: classes,
          }
        )}
      >
        <div
          className={classNames({ [titleSectionClasses]: titleSectionClasses })}
        >
          <h2 className=" font-bold text-[46px]   leading-[48px] text-[#0D283E] text-center">
            Validator{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
              {" "}
              Roles
            </span>
          </h2>
          <p className="text-[16px] text-center  py-[30px] md:max-w-[791px] mx-auto">
            Validators will produce blocks on the Fetch Network mainnet, in
            exchange for rewards, but also experience a number of other
            benefits.
          </p>
        </div>
        <div
          className={classNames("mx-auto", {
            [cardSectionClasses]: cardSectionClasses,
          })}
        >
          <div
            ref={socialCardsRef}
            className="grid grid-cols-1 mx-auto items-center lg:grid-cols-3 gap-1 gap-y-[16px] md:gap-[40px] justify-center md:pt-[55px] "
          >
            {textArray.map((text, index) => (
              <div
                key={index}
                className="bg-gradient-to-br  from-[#4789CD] via-[#478acd00] to-[#478acd7a] px-[1.5px]  rounded-3xl "
              >
                <div
                  style={{
                    boxShadow: "20px 20px 60px 0px rgba(71, 137, 205, 0.16)",
                  }}
                  className="bg-white rounded-3xl flex flex-col items-center justify-center text-center back p-[40px]"
                >
                  <div className="min-h-[158px]">
                    <img
                      src={blueTick}
                      alt="blue tick"
                      className="items-center justify-center mx-auto"
                    />
                    <p className="md:max-w-[317px] w-auto text-[16px] mt-[20px]">
                      {text}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="bg-white ">
        <div
          className={classNames(
            " py-[50px] px-[20px]  md:pt-[100px] container mx-auto md:px-[120px]  bg-white md:pb-[150px]",
            {
              [classes]: classes,
            }
          )}
        >
          <div
            className={classNames({
              [titleSectionClasses]: titleSectionClasses,
            })}
          >
            <h2 className=" font-bold text-[46px]   leading-[48px] text-[#0D283E] text-center">
              Validator{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-500 to-cyan-400">
                {" "}
                Revenue
              </span>
            </h2>
            <p className="text-[16px] text-center  py-[30px] md:max-w-[794px] mx-auto">
              Similarly to most other Proof-of-Stake blockchains, the Fetch.ai
              AI ledger provides revenue to validators in the form of block
              rewards and transaction fees. Smaller holders of FET are able to
              delegate their stake to validators in exchange for a share of the
              rewards, as determined by the validator.
            </p>
          </div>
          <div className="mx-auto w-full flex flex-col justify-center items-center lg:grid lg:grid-cols-2 gap-8 lg:pt-[80px]">
            <div className="w-full md:relative lg:w-3/4">
              <div className="relative items-center justify-center hidden lg:flex h-96">
                <img
                  src={toolBox}
                  className="object-cover w-full rounded-2xl"
                  alt="mission 3"
                />
                <img
                  src={greenGlow}
                  className="absolute object-cover w-full rounded-2xl -top-36 -left-32"
                  alt="mission 3"
                />
              </div>
            </div>

            <div className=" flex flex-col justify-center z-10 relative md:mt-0 max-w-[460px] ">
              <div className="relative flex items-center justify-center lg:hidden h-96">
                <img
                  src={toolBox}
                  className="object-cover w-full rounded-2xl"
                  alt="mission 3"
                />
                <img
                  src={greenGlow}
                  className="absolute object-cover w-full rounded-2xl -top-36 -left-32"
                  alt="mission 3"
                />
              </div>
              <div className="mb-6 text-left">
                <ul className="ml-4 mt-2 text-[16px]">
                  {listData.map((item, index) => (
                    <li key={index} className="blue-bullet">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="24"
                          viewBox="0 0 25 24"
                          fill="none"
                        >
                          <circle
                            cx="12.5"
                            cy="12"
                            r="6.1853"
                            fill="url(#paint0_linear_646_4637)"
                          />
                          <defs>
                            <linearGradient
                              id="paint0_linear_646_4637"
                              x1="-1.37558"
                              y1="22.9134"
                              x2="30.8394"
                              y2="2.37631"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop offset="0.285035" stopColor="#4789CD" />
                              <stop offset="0.755255" stopColor="#3CD7F6" />
                            </linearGradient>
                          </defs>
                        </svg>
                        <p className="pb-[20px] text-[18px] font-semibold items-center justify-center mt-4 ml-1">
                          {item.heading}
                        </p>
                      </div>
                      <p className="ml-7">{item.description}</p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="">
        <div className="flex justify-center lg:-mt-20 items-center mt-4 px-[20px] md:mt-12">
          <div className="lg:h-[560.25px] lg:w-[996px] w-[350px] h-[198px] rounded-[20px] overflow-hidden">
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/211C49yr1L8"
              title="YouTube Video"
              frameBorder="0"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </section>
    </>
  );
};

export default RolesRevenue;
